import React from 'react';
import {Router, Switch, Route} from 'dva/router';
import dynamic from 'dva/dynamic'

function RouterConfig({history, app}) {

    const routes = [
        {
            path: '/wip',
            models: () => [import('./pages/wip/model')],
            component: () => import('./pages/wip/index'),
        },
        {
            path: '/mi',
            models: () => [import('./pages/mi/model')],
            component: () => import('./pages/mi/index'),
        },

        {
            path: '/sales/delivery/:orderDate',
            models: () => [import('./pages/sales/delivery/model')],
            component: () => import('./pages/sales/delivery/index')
        },
        {
            path: '/sales/finelineshipment/:pushDate',
            models: () => [import('./pages/sales/finelineshipment/model')],
            component: () => import('./pages/sales/finelineshipment/index')
        },
        {
            path: '/sales/domesticshipment/:pushDate',
            models: () => [import('./pages/sales/domesticshipment/model')],
            component: () => import('./pages/sales/domesticshipment/index')
        },
        {
            path: '/sales/lay/:orderDate',
            models: () => [import('./pages/sales/lay/model')],
            component: () => import('./pages/sales/lay/index')
        },
        {
            path: '/sales/store/:orderDate',
            models: () => [import('./pages/sales/store/model')],
            component: () => import('./pages/sales/store/index')
        },
        {
            path: '/sales/jm2product/:productDate',
            models: () => [import('./pages/sales/jm2product/model')],
            component: () => import('./pages/sales/jm2product/index')
        },
        {
            path: '/sales/complaint/:complaintDate',
            models: () => [import('./pages/sales/complaint/model')],
            component: () => import('./pages/sales/complaint/index')
        },
        {
            path: '/sales/complainttotal/:complaintDate',
            models: () => [import('./pages/sales/complainttotal/model')],
            component: () => import('./pages/sales/complainttotal/index')
        },
        {
            path: '/sales/complaint2/:complaintDate',
            models: () => [import('./pages/sales/complaint2/model')],
            component: () => import('./pages/sales/complaint2/index')
        },
        {
            path: '/sales/complaintgroup/:complaintDate',
            models: () => [import('./pages/sales/complaintgroup/model')],
            component: () => import('./pages/sales/complaintgroup/index')
        },
        {
            path: '/sales/complaintgroup2/:complaintDate',
            models: () => [import('./pages/sales/complaintgroup2/model')],
            component: () => import('./pages/sales/complaintgroup2/index')
        },
        {
            path: '/sales/complaintgroup3/:complaintDate',
            models: () => [import('./pages/sales/complaintgroup3/model')],
            component: () => import('./pages/sales/complaintgroup3/index')
        },
        {
            path: '/sales/complaintgroup4/:complaintDate',
            models: () => [import('./pages/sales/complaintgroup4/model')],
            component: () => import('./pages/sales/complaintgroup4/index')
        },
        {
            path: '/sales/complaintgroup5/:complaintDate',
            models: () => [import('./pages/sales/complaintgroup5/model')],
            component: () => import('./pages/sales/complaintgroup5/index')
        },
        {
            path: '/sales/groupcustomer/:complaintDate',
            models: () => [import('./pages/sales/groupcustomer/model')],
            component: () => import('./pages/sales/groupcustomer/index')
        },
        {
            path: '/sales/groupcustomera/:complaintDate',
            models: () => [import('./pages/sales/groupcustomer_a/model')],
            component: () => import('./pages/sales/groupcustomer_a/index')
        },
        {
            path: '/sales/groupcustomerb/:complaintDate',
            models: () => [import('./pages/sales/groupcustomer_b/model')],
            component: () => import('./pages/sales/groupcustomer_b/index')
        },
        {
            path: '/sales/groupcustomerc/:complaintDate',
            models: () => [import('./pages/sales/groupcustomer_c/model')],
            component: () => import('./pages/sales/groupcustomer_c/index')
        },
        {
            path: '/sales/oatodo/:oaDate',
            models: () => [import('./pages/sales/oatodo/model')],
            component: () => import('./pages/sales/oatodo/index')
        },
        ,
        {
            path: '/sales/oasdgtodo/:oaDate',
            models: () => [import('./pages/sales/oasdgtodo/model')],
            component: () => import('./pages/sales/oasdgtodo/index')
        },
        {
            path: '/sales/backcomplaint/:backcomplaintDate',
            models: () => [import('./pages/sales/backcomplaint/model')],
            component: () => import('./pages/sales/backcomplaint/index')
        },
        {
            path: '/sales/eq/:sid',
            models: () => [import('./pages/sales/eq/model')],
            component: () => import('./pages/sales/eq/index')
        },
        {
            path: '/sales/combinationplate/:pushDate',
            models: () => [import('./pages/sales/combinationplate/model')],
            component: () => import('./pages/sales/combinationplate/index')
        },
        {
            path: '/sales/orderdelay/:org/:type',
            models: () => [import('./pages/sales/orderdelay/model')],
            component: () => import('./pages/sales/orderdelay/index')
        },
        {
            path: '/sales/deliverygoods/:deliverydate',
            models: () => [import('./pages/sales/deliverygoods/model')],
            component: () => import('./pages/sales/deliverygoods/index')
        },
        {
            path: '/sales/midelay/:midate',
            models: () => [import('./pages/sales/midelay/model')],
            component: () => import('./pages/sales/midelay/index')
        },
        {
            path: '/sales/riskorder/:pushDate',
            models: () => [import('./pages/sales/riskorder/model')],
            component: () => import('./pages/sales/riskorder/index')
        },
        {
            path: '/sales/mustpayrate/:orderDate/:type',
            models: () => [import('./pages/sales/mustpayrate/model')],
            component: () => import('./pages/sales/mustpayrate/index')
        },

        {
            path: '/sales/equipment/:empid',
            models: () => [import('./pages/sales/equipment/model')],
            component: () => import('./pages/sales/equipment/index')
        },
        {
            path: '/sales/ppmaterial/:pushDate',
            models: () => [import('./pages/sales/ppmaterial/model')],
            component: () => import('./pages/sales/ppmaterial/index')
        },
        {
            path: '/sales/npi/:pushDate',
            models: () => [import('./pages/sales/npi/model')],
            component: () => import('./pages/sales/npi/index')
        },
        {
            path: '/sales/order/:orderDate',
            models: () => [import('./pages/sales/order/model')],
            component: () => import('./pages/sales/order/index')
        },
        {
            path: '/sales/yoy/:orderDate',
            models: () => [import('./pages/sales/yoy/model')],
            component: () => import('./pages/sales/yoy/index')
        },
        {
            path: '/sales/busy/:orderDate',
            models: () => [import('./pages/sales/busy/model')],
            component: () => import('./pages/sales/busy/index')
        },
        {
            path: '/sales/busybak/:orderDate',
            models: () => [import('./pages/sales/busybak/model')],
            component: () => import('./pages/sales/busybak/index')
        },
        {
            path: '/sales/sort/:orderDate',
            models: () => [import('./pages/sales/sort/model')],
            component: () => import('./pages/sales/sort/index')
        },
        {
            path: '/sales/sum/:orderDate',
            models: () => [import('./pages/sales/sum/model')],
            component: () => import('./pages/sales/sum/index')
        },
        {
            path: '/sales/send/:orderDate',
            models: () => [import('./pages/sales/send/model')],
            component: () => import('./pages/sales/send/index')
        }
    ]

    return (
        <Router history={history}>
            <Switch>
                {
                    routes.map(({path, ...dynamics}, key) => (
                        <Route key={key}
                               exact
                               path={path}
                               component={dynamic({
                                   app,
                                   ...dynamics,
                               })}
                        />
                    ))
                }
            </Switch>
        </Router>
    )
}

export default RouterConfig;
